<template>
  <div class="filters">
    <v-container fluid>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="4">
          <v-select
            :items="estados"
            label="Estado"
            dense
            @change="updateFilter()"
            v-model="estado"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "FiltersLeads",
  props: ["estados"],
  data: () => ({
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    date: [],
    menu: false,
    modal: false,
    menu2: false,
    estado: "All",
  }),
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  methods: {
    updateFilter: async function () {
      this.$emit("change-estado", this.estado);
    },
  },
};
</script>

<style scoped>
.filters {
  margin: 20px 40px 0px 296px;
}

.input-datas {
  margin-top: -2px;
  font-size: 14px;
}
</style>